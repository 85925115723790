import React from "react"
import { Link } from "gatsby"

import Logo from "../../components/logo"
import LoginLayout from "../../components/login-layout"
import { RegisterForm } from "../../components/forms"
import SplashImage from "../../components/splash-image"
import Seo from "../../components/seo"

export default function RegisterPage() {
  return (
    <>
      <Seo title="Register" />
      <LoginLayout>
        <section className="flex flex-col md:flex-row h-screen items-center">
          <div className="bg-blue-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
            <SplashImage />
          </div>

          <aside className="bg-white w-full md:max-w-md lg:max-w-full md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 py-12 flex flex-col overflow-auto">
            <Link to="/" className="flex flex-shrink-0 items-center">
              <Logo />
              <h1 className="serif xl:text-xl font-bold">
                British Society <i className="font-normal text-gray-700">of</i>{" "}
                <span className="block">Skeletal Radiologists</span>
              </h1>
            </Link>

            <h2 className="text-xl md:text-2xl font-bold leading-tight mt-12 mb-4">
              Join the BSSR
            </h2>

            <p className="mb-8 bg-yellow-200 border-yellow-400 border-2 rounded p-4">
              To register with the BSSR, please complete the fields below and
              click the register button. Once registered you will be taken to
              the login page where you will be able to sign into your new online
              account.
            </p>

            <RegisterForm />

            <hr className="my-6 border-gray-300 w-full" />

            <p>
              Already have an account?{" "}
              <Link to="/login" className="text-indigo-700 font-semibold">
                Sign in
              </Link>
            </p>
          </aside>
        </section>
      </LoginLayout>
    </>
  )
}
